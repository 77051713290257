<template>
	<div v-if="canSeeSignatureIcon">
		<v-tooltip top>
			<template v-slot:activator="{ on }">
				<v-avatar :class="`${signatureStatus.color} signature-icon-overlay`" :size="`${iconSize}`" v-on="on">
					<v-icon dark>mdi-draw</v-icon>
				</v-avatar>
			</template>
			<span v-t="`signature.documents.statuses.${signatureStatus.status}`" />
		</v-tooltip>
	</div>
</template>

<script>
import SignatureModuleGuard from '@/mixins/ModulesGuards/Signature/SignatureModuleGuard'
import DocumentVersionsService from '@/services/Documents/DocumentVersionsService'

export default {
	name: 'SignatureIcon',
	mixins: [SignatureModuleGuard],
	props: {
		small: {
			type: Boolean,
			required: false,
			default: false
		},
		medium: {
			type: Boolean,
			required: false,
			default: false
		},
		large: {
			type: Boolean,
			required: false,
			default: false
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		document: function () {
			return this.model?.is_document ? this.model : null
		},
		currentVersion: function () {
			let result = null
			if (this.version != null) {
				result = this.version
			} else if (this.document && this.document.latestVersion) {
				result = this.document.latestVersion
			}
			return result
		},
		hasSignature: function () {
			return this.document && this.document.has_signatories
		},
		signatureStatus: function () {
			return this.hasSignature && this.currentVersion != null ? this.currentVersion.signatures : null
		},
		iconSize: function () {
			let result
			if (this.small) {
				result = 24
			} else if (this.medium) {
				result = 48
			} else if (this.large) {
				result = 64
			} else {
				result = 16
			}
			return result
		},
		canSeeSignatureIcon: function () {
			return this.signatureStatus != null
		}
	},
	watch: {
		document: {
			handler: function () {
				return this.loadStatus()
			}
		},
		currentVersion: {
			handler: function () {
				return this.loadStatus()
			}
		}
	},
	mounted: function () {
		return this.loadStatus()
	},
	methods: {
		loadStatus: function () {
			let result
			if (this.document && this.hasSignature) {
				if (!this.currentVersion) {
					result = DocumentVersionsService.getDocumentVersions(this.document.vendor_id, this.document)
				} else if (this.currentVersion && (!this.currentVersion.signatures || this.currentVersion.signatures.length == 0)) {
					result = this.service.getSignatureStatus(this.document.vendor_id, this.document.id, this.currentVersion.id).then(signatureStatus => {
						signatureStatus.document_id = this.document.id
						signatureStatus.version_id = this.currentVersion.id
						DocumentVersionsService.addOrUpdateDocumentVersionSignature(signatureStatus)
					})
				}
			}
			return result
		}
	}
}
</script>

<style scope>
.signature-icon-overlay {
	opacity: 0.8;
}
</style>
